package actions

import entities.CompCalculation
import entities.ExchangeRate
import entities.ThirtyDayAverageQuote
import kotlinx.datetime.LocalDateTime
import redux.RAction

data class SharePriceUpdate(val sharePrice: ThirtyDayAverageQuote) : RAction
data class AddAlias(val alias: String) : RAction
data class AddVestingShares(val amount: Int) : RAction
data class AddBaseComp(val amount: Double) : RAction
data class AddOtherCash(val amount: Double) : RAction
data class AddTcT(val amount: Double) : RAction
data class AddCurrency(val exchangeRate: ExchangeRate) : RAction
data class ClearComp(val now:Boolean):RAction

data class UpdateCompList(val compCalc: CompCalculation) : RAction
data class LoadCompList(val key: String) : RAction
data class StoreCompList(val list: ArrayList<CompCalculation>): RAction

data class UpdateExpectedExchangeRateCount(val count: Int) : RAction
data class UpdateExchangeRate(
    val source: String,
    val destination: String,
    val exchangeRate: Double,
    val timeStamp: LocalDateTime
) : RAction