package components

import actions.LoadCompList
import csstype.ClassName
import entities.CompCalculation
import entities.ExchangeRate
import kotlinx.browser.localStorage
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.table
import react.dom.html.ReactHTML.tbody
import react.dom.html.ReactHTML.td
import react.dom.html.ReactHTML.th
import react.dom.html.ReactHTML.thead
import react.dom.html.ReactHTML.tr
import react.redux.useDispatch
import react.redux.useSelector
import state.ApplicationStore

external interface CompTableState : State {
    var compList: List<CompCalculation>
}

val CompTable = FC<Props> { _ ->



    val compList = useSelector { state: ApplicationStore ->
        state.compList
    }
    val mainScope = MainScope()
    val loadCompList: Dispatch<LoadCompList> = useDispatch()
    useEffectOnce {
        mainScope.launch {
            val rawList = localStorage.getItem("compList")

            if (rawList != null && rawList.isNotEmpty() && rawList != "[]") {
                loadCompList(LoadCompList("compList"))
            }
        }
    }

    val exchangeRateList: List<ExchangeRate> = useSelector { state: ApplicationStore ->
        state.exchangeRateList
    }

    fun tctColor(tctDifference:Double) = if (tctDifference < 0.0) {
        ClassName("text-danger")
    } else if (tctDifference > 0.0) {
        ClassName("text-success")
    } else {
        ClassName("text-dark")
    }

    fun exchangeRateAdjustedTC(comp: CompCalculation): Double {
        val forex = exchangeRateList.firstOrNull { it.destination == comp.currency }
        return if (forex != null)
            comp.baseComp + comp.otherCash + (comp.shares.times(comp.sharePrice).times(forex.exchangeRate))
        else
            0.0
    }

    try {
        div {
            id = "compTableDiv"
            div {
                className = ClassName("row")
                table {
                    className = ClassName("table")
                    thead {
                        tr {
                            th {
                                scope = "col"
                                +"Alias"
                            }
                            th {
                                scope = "col"
                                +"TCT"
                            }
                            th {
                                scope = "col"
                                +"Current TC"
                            }
                            th {
                                scope = "col"
                                +"Difference"
                            }
                            th {
                                scope = "col"
                                +"TCT Attainment"
                            }
                        }
                    }
                    tbody {
                        for (comp: CompCalculation in compList.toMutableList()) {
                            val numberFormatter = getNumberFormatter(comp.currency)
                            tr {
                                th {
                                    scope = "row"
                                    +comp.alias
                                }
                                td {
                                    +numberFormatter.format(comp.totalCompTarget).toString()
                                }
                                td {
                                    +numberFormatter.format(exchangeRateAdjustedTC(comp)).toString()
                                }
                                td {
                                    className = tctColor(exchangeRateAdjustedTC(comp) - comp.totalCompTarget)
                                    +numberFormatter.format(exchangeRateAdjustedTC(comp) - comp.totalCompTarget)
                                        .toString()
                                }
                                td {
                                    className = tctColor(exchangeRateAdjustedTC(comp) - comp.totalCompTarget)
                                    +(exchangeRateAdjustedTC(comp) / comp.totalCompTarget).times(100).asDynamic()
                                        .toFixed(2)
                                        .toString().plus("%")
                                }
                            }
                        }

                    }
                }
            }
        }
    } catch (e: Exception) {
        console.log(e)
        div {
            id = "compTableErrorDiv"
            div {
                className = ClassName("row")
                div {
                    className = ClassName("col-auto")
                    +"No Data"
                }
            }
        }
    }
}