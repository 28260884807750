package components

import actions.ClearComp
import actions.StoreCompList
import actions.UpdateCompList
import csstype.ClassName
import entities.CompCalculation
import kotlinx.browser.localStorage
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import react.Dispatch
import react.FC
import react.Props
import react.dom.html.ButtonType
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.redux.useDispatch
import react.redux.useSelector
import state.ApplicationStore

val SaveCompCalcButton = FC<Props> { props ->

    val updateCompList: Dispatch<UpdateCompList> = useDispatch()
    val saveCompList: Dispatch<StoreCompList> =  useDispatch()
    val clearCompValues: Dispatch<ClearComp> = useDispatch()

    val items = useSelector { state: ApplicationStore ->
        state.compList
    }

    val currentComp = useSelector { state: ApplicationStore ->
        state.compCalculation
    }

    fun clearInputs(){
        clearCompValues(ClearComp(true))
    }

    fun updateStore(comp: CompCalculation) {
        updateCompList(UpdateCompList(comp))
        localStorage.setItem("compList", Json.encodeToString(items))
        clearInputs()
    }

    div {
        //className = ClassName("col-auto")
        button {
            className = ClassName("btn btn-primary mb-3")
            type = ButtonType.button
            onClick = { event ->
                updateStore(currentComp)
            }
            +"Save Employee"

        }
    }

}