package reducers

import actions.*
import entities.CompCalculation
import entities.ExchangeRate
import kotlinx.browser.localStorage
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import redux.RAction
import state.ApplicationStore


fun compCalculationReducer(state: ApplicationStore, action: RAction): ApplicationStore {
    val newState = when (action) {
        is SharePriceUpdate -> {
            state.compCalculation.sharePrice = action.sharePrice.averagePrice
            state.compCalculation.vestingValue = state.compCalculation.vestingValue * action.sharePrice.averagePrice
            state.compCalculation.quoteDate = action.sharePrice.endDate
            state.compCalculation = state.compCalculation.copy()
            for(comp in state.compList){
                comp.sharePrice = action.sharePrice.averagePrice
            }

            state.copy()
        }
        is AddVestingShares -> {
            state.compCalculation.shares = action.amount
            state.compCalculation.vestingValue = action.amount * state.compCalculation.sharePrice
            state.compCalculation.totalComp =
                state.compCalculation.vestingValue + state.compCalculation.baseComp + state.compCalculation.otherCash
            state.compCalculation = state.compCalculation.copy()
            state.copy()
        }
        is AddBaseComp -> {
            state.compCalculation.baseComp = action.amount
            state.compCalculation.totalComp =
                state.compCalculation.vestingValue + state.compCalculation.baseComp + state.compCalculation.otherCash
            state.compCalculation = state.compCalculation.copy()
            state.copy()
        }
        is AddOtherCash -> {
            state.compCalculation.otherCash = action.amount
            state.compCalculation.totalComp =
                state.compCalculation.vestingValue + state.compCalculation.baseComp + state.compCalculation.otherCash
            state.compCalculation = state.compCalculation.copy()
            state.copy()
        }
        is AddTcT -> {
            state.compCalculation.totalCompTarget = action.amount
            state.compCalculation = state.compCalculation.copy()
            state.copy()
        }
        is AddCurrency -> {
            state.compCalculation.currency = action.exchangeRate.destination
            state.compCalculation = state.compCalculation.copy()
            state.copy()
        }
        is AddAlias -> {
            state.compCalculation.alias = action.alias
            state.compCalculation = state.compCalculation.copy()
            state.copy()
        }
        is ClearComp -> {
            state.compCalculation = CompCalculation()
            state.copy()
        }
        is UpdateExchangeRate -> {
            val item = state.exchangeRateList.firstOrNull { item ->
                item.source == action.source && item.destination == action.destination
            }
            if (item != null) {
                state.exchangeRateList.remove(item)
            }
            val newItem = ExchangeRate(action.source, action.destination, action.exchangeRate, action.timeStamp)
            state.exchangeRateList.add(newItem)
            state.exchangeRateList = state.exchangeRateList.sortedBy { it.destination }.toMutableList()
            state.copy()
        }
        is UpdateExpectedExchangeRateCount -> {
            state.expectedExchangeRates = action.count
            state.copy()
        }
        is UpdateCompList -> {
            val currentItem = state.compList.firstOrNull { action.compCalc.alias == it.alias }
            if (currentItem != null) {
                state.compList.remove(currentItem)
            }
            state.compList.add(action.compCalc)
            state.compList = ArrayList(state.compList.sortedBy { it.alias })
            state.copy()
        }
        is StoreCompList -> {
            localStorage.setItem("compList", Json.encodeToString(action.list))
            state.compList = action.list
            state.copy()
        }
        is LoadCompList -> {
            val localStorageList = localStorage.getItem(action.key)
            val parsedList:Array<CompCalculation> = if (localStorageList == null || localStorageList == "[]") {
                Json.decodeFromString("[]")
            } else {
                Json.decodeFromString(localStorageList)
            }
            state.compList= ArrayList<CompCalculation>()
            state.compList.addAll(parsedList)
            state.copy()
        }
        else -> state.copy()
    }
    return newState
}