import components.AppWrapper
import kotlinx.browser.document
import react.create
import react.dom.client.createRoot
import reducers.compCalculationReducer
import redux.createStore
import redux.rEnhancer
import state.ApplicationStore

val globalStore = createStore(::compCalculationReducer, ApplicationStore(), rEnhancer())
fun main() {
    val container = document.getElementById("root") ?: error("Couldn't find container!")
    val root = createRoot(container)
    root.render(
        AppWrapper.create()
    )
}