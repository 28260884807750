package entities

import kotlinx.datetime.LocalDate
import kotlinx.serialization.Serializable

@Serializable
data class ThirtyDayAverageQuote(
    val symbol: String,
    val averagePrice: Double,
    val startDate: LocalDate,
    val endDate: LocalDate,
    val itemsInCalc: Int
) {
    companion object {
        const val path = "/stock/quote/thirtyDayAverage"

        fun fromTO(to: ThirtyDayAverageQuoteTO): ThirtyDayAverageQuote {
            val parsedStart =
                LocalDate(
                    to.startDate!!["value"]!!["year"]!!,
                    to.startDate["value"]!!["month"]!!,
                    to.startDate["value"]!!["day"]!!
                )

            val parsedEnd =
                LocalDate(
                    to.endDate!!["value"]!!["year"]!!,
                    to.endDate["value"]!!["month"]!!,
                    to.endDate["value"]!!["day"]!!
                )

            return ThirtyDayAverageQuote(
                to.symbol!!,
                to.averagePrice!!,
                parsedStart,
                parsedEnd,
                to.itemsInCalc
            )
        }

    }
}

@Serializable
data class ThirtyDayAverageQuoteTO(
    val symbol: String?,
    val averagePrice: Double?,
    val startDate: Map<String, Map<String, Int>>?,
    val endDate: Map<String, Map<String, Int>>?,
    val itemsInCalc: Int
)