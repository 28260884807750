package components

import actions.AddVestingShares
import csstype.ClassName
import react.Dispatch
import react.FC
import react.Props
import react.dom.html.InputType
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.label
import react.redux.useDispatch
import react.redux.useSelector
import state.ApplicationStore

val ShareCount = FC<Props> { _ ->
    val amount = useSelector { state: ApplicationStore ->
        state.compCalculation.shares
    }

    val dispatch: Dispatch<AddVestingShares> = useDispatch()
    div {
        className = ClassName("form-group")
        label {
            htmlFor = "shareCount"
            +"Shares Vesting"
        }
        input {
            className = ClassName("form-control")
            id = "shareCount"
            name = id
            type = InputType.text
            defaultValue = amount.toString()
            onChange = { event ->
                val newAmount = event.target.value
                if (newAmount.isNotEmpty()) {
                    dispatch(AddVestingShares(newAmount.toInt()))
                }
            }
        }

    }
}