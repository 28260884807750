package components

import csstype.ClassName
import entities.CompCalculation
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.form
import react.redux.useSelector
import state.ApplicationStore

val CompForm = FC<Props> { props ->
    val comp: CompCalculation = useSelector { state: ApplicationStore -> state.compCalculation }

    form {
        div {
            className = ClassName("row g-3")
            div {
                className = ClassName("col-auto")
                Alias {}
            }
            div {
                className = ClassName("col-auto")
                ShareCount {}
                VestingShareValue {}
            }
        }
        div {
            className = ClassName("row g-3")
            div {
                className = ClassName("col-auto")
                BaseComp {}
            }
            div {
                className = ClassName("col-auto")
                OtherCash {}
            }
        }
        SpacerRow {
            this.divId = "spacerFormDivBaseComp"
            this.isForm = false
        }
        div {
            className = ClassName("row g-3")
            div {
                className = ClassName("col-auto")
                TotalCompTarget {}
            }
            div {
                className = ClassName("col-auto")
                LocalCurrency {}
            }
        }
        SpacerRow {
            this.divId = "spacerFormDivTotalComp"
            this.isForm = false
        }
        div {
            className = ClassName("row g-3")
            div {
                className = ClassName("col-auto")
                SaveCompCalcButton {}
            }

        }

    }

}

