package state

import entities.CompCalculation
import entities.ExchangeRate

class ApplicationStore(
    var compCalculation: CompCalculation = CompCalculation(),
    var exchangeRateList: MutableList<ExchangeRate> = ArrayList(),
    var expectedExchangeRates: Int = 0,
    var compList: ArrayList<CompCalculation> = ArrayList()
) {
    fun copy(): ApplicationStore {
        return ApplicationStore(
            compCalculation.copy(),
            mutableListOf<ExchangeRate>().apply { addAll(exchangeRateList) },
            expectedExchangeRates,
            compList
        )
    }
}