package components

import actions.AddAlias
import csstype.ClassName
import react.Dispatch
import react.FC
import react.Props
import react.dom.html.InputType
import react.dom.html.ReactHTML
import react.redux.useDispatch
import react.redux.useSelector
import state.ApplicationStore

val Alias = FC<Props> { _ ->
    val alias = useSelector { state: ApplicationStore ->
        state.compCalculation.alias
    }
    val dispatch: Dispatch<AddAlias> = useDispatch()
    ReactHTML.div {
        className = ClassName("form-group")
        ReactHTML.label {
            htmlFor = "alias"
            +"Amazon Alias"
        }
        ReactHTML.input {
            className = ClassName("form-control")
            id = "alias"
            name = id
            type = InputType.text
            defaultValue = alias
            onChange = { event ->
                dispatch(AddAlias(event.target.value))
            }
        }

    }
}