package components

import csstype.ClassName
import io.ktor.util.*
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.redux.useSelector
import state.ApplicationStore

fun getNumberFormatter(currency: String): dynamic {
    return when (currency) {
        "GBP" -> {
            js("Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' })")
        }
        "EUR" -> {
            js("Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' })")
        }
        "ILS" -> {
            js("Intl.NumberFormat('he-IL', { style: 'currency', currency: 'ILS' })")
        }
        else -> {
            js("Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })")
        }
    }

}

val SharePrice = FC<Props> { props ->

    val sharePrice = useSelector { state: ApplicationStore ->
        val forex = state.exchangeRateList.firstOrNull { it.destination == state.compCalculation.currency }
        state.compCalculation.sharePrice.times(forex?.exchangeRate ?: 1.0)
    }

    val quoteDate = useSelector { state: ApplicationStore ->
        "${
            state.compCalculation.quoteDate.month.name.toLowerCasePreservingASCIIRules()
                .replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }
        } ${state.compCalculation.quoteDate.dayOfMonth}, ${state.compCalculation.quoteDate.year}"
    }

    val numberFormatter = useSelector { state: ApplicationStore ->
        getNumberFormatter(state.compCalculation.currency)
    }

    val symbol = "AMZN"

    div {
        id = "quoteDiv"
        div {
            className = ClassName("row")
            div {
                className = ClassName("col")
                +"Thirty day average price of ${symbol} is ${numberFormatter.format(sharePrice)} as of $quoteDate"
            }
        }
    }
}