package components

import csstype.ClassName
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.span
import react.redux.useSelector
import state.ApplicationStore
import kotlin.math.absoluteValue

val TotalCompCalculation = FC<Props> {
    val totalCompensation = useSelector { state: ApplicationStore ->
        val forex = state.exchangeRateList.firstOrNull { it.destination == state.compCalculation.currency }
        (state.compCalculation.baseComp + state.compCalculation.otherCash + (state.compCalculation.shares
            .times(state.compCalculation.sharePrice)
            .times(forex?.exchangeRate ?: 1.0)))
    }

    val numberFormatter = useSelector { state: ApplicationStore ->
        getNumberFormatter(state.compCalculation.currency)
    }

    val tct = useSelector { state: ApplicationStore ->
        state.compCalculation.totalCompTarget
    }

    val tctDifference = totalCompensation.minus(tct)

    val sign = if (tctDifference < 0.0) {
        "-"
    } else if (tctDifference > 0.0) {
        "+"
    } else {
        ""
    }

    val tctColor = if (tctDifference < 0.0) {
        ClassName("text-danger")
    } else if (tctDifference > 0.0) {
        ClassName("text-success")
    } else {
        ClassName("text-dark")
    }

    div {
        id = "vestingShareValueDiv"
        div {
            className = ClassName("row")
            div {
                className = ClassName("col-auto")
                +"Projected Total Compensation is ${numberFormatter.format(totalCompensation)}"
            }
        }
        div {
            className = ClassName("row")
            div {
                className = ClassName("col-md-9")
                +"Difference in Total Compensation vs Target: "
                span {
                    className = tctColor
                    +"${numberFormatter.format(tctDifference.absoluteValue)}"
                }
            }
        }
    }
}