package entities

import kotlinx.datetime.LocalDateTime

class ExchangeRate(
    val source: String,
    val destination: String,
    val exchangeRate: Double,
    val timeStamp: LocalDateTime
) {

    fun copy(): ExchangeRate {
        return ExchangeRate(
            source,
            destination,
            exchangeRate,
            timeStamp
        )
    }

}