package components

import actions.AddBaseComp
import csstype.ClassName
import react.Dispatch
import react.FC
import react.Props
import react.dom.html.InputType
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.label
import react.redux.useDispatch
import react.redux.useSelector
import state.ApplicationStore

val BaseComp = FC<Props> { props ->
    val amount = useSelector { state: ApplicationStore ->
        state.compCalculation.baseComp
    }
    val dispatch: Dispatch<AddBaseComp> = useDispatch()
    div {
        className = ClassName("form-group")
        label {
            htmlFor = "baseComp"
            +"Base Comp"
        }
        input {
            className = ClassName("form-control")
            id = "baseComp"
            name = id
            type = InputType.text
            defaultValue = amount.toString()
            onChange = { event ->
                val newAmount = event.target.value
                if (newAmount.isNotEmpty()) {
                    dispatch(AddBaseComp(newAmount.toDouble()))
                }

            }
        }

    }
}