package components

import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.small
import react.redux.useSelector
import state.ApplicationStore

val VestingShareValue = FC<Props> {

    val numberFormatter = useSelector { state: ApplicationStore ->
        getNumberFormatter(state.compCalculation.currency)
    }

    val shareValue = useSelector { state: ApplicationStore ->
        val forex = state.exchangeRateList.firstOrNull { it.destination == state.compCalculation.currency }
        state.compCalculation.sharePrice.times(state.compCalculation.shares).times(forex?.exchangeRate ?: 1.0)
    }

    div {
        id = "vestingShareValueDiv"
        small {
            +"Value of shares vesting this comp year is ${numberFormatter.format(shareValue)}."
        }
    }

}

