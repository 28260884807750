package components

import actions.AddCurrency
import csstype.ClassName
import entities.ExchangeRate
import react.Dispatch
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.label
import react.dom.html.ReactHTML.option
import react.dom.html.ReactHTML.select
import react.redux.useDispatch
import react.redux.useSelector
import state.ApplicationStore

val LocalCurrency = FC<Props> { props ->
    val expectedExchangeRateCount: Int = useSelector { state: ApplicationStore -> state.expectedExchangeRates }
    val currencyList: List<ExchangeRate> = useSelector { state: ApplicationStore ->
        if (expectedExchangeRateCount == state.exchangeRateList.size) {
            val current = state.exchangeRateList
            if (current.firstOrNull { it.destination == "USD" } != null) {
                val usd = state.exchangeRateList.first { it.destination == "USD" }
                val index = state.exchangeRateList.indexOf(usd)
                current.remove(usd)
                current.add(0, usd)
            }
            current
        } else {
            ArrayList()
        }
    }
    val selectedCurrency: String = useSelector { state: ApplicationStore ->
        state.compCalculation.currency
    }

    val dispatch: Dispatch<AddCurrency> = useDispatch()

    ReactHTML.div {
        className = ClassName("form-group")
        label {
            htmlFor = "currency"
            +"Local Currency"
        }
        select {
            className = ClassName("form-control")
            id = "currency"
            name = id
            defaultValue = "USD"
            onChange = { event ->
                val currencyName = event.target.value
                val selectedItem = currencyList.first { it.destination == currencyName }
                dispatch(AddCurrency(selectedItem))
            }

            for (currency in currencyList) {
                option {
                    value = currency.destination
                    +currency.destination
                }
            }
        }

    }
}