package components

import actions.LoadCompList
import actions.SharePriceUpdate
import actions.UpdateExchangeRate
import actions.UpdateExpectedExchangeRateCount
import api.getForexQuote
import api.getThirtyDayAvgStockQuote
import csstype.ClassName
import entities.ForexRequest
import entities.QuoteRequest
import entities.ThirtyDayAverageQuote
import kotlinx.coroutines.*
import kotlinx.datetime.*
import react.Dispatch
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.redux.useDispatch
import react.useEffectOnce

private val scope = MainScope()

val App = FC<Props> { _ ->
    val exchangeRateDispatcher: Dispatch<UpdateExchangeRate> = useDispatch()
    val exchangeRateCountDispatcher: Dispatch<UpdateExpectedExchangeRateCount> = useDispatch()
    val loadCompList: Dispatch<LoadCompList> = useDispatch()
    val dispatch: Dispatch<SharePriceUpdate> = useDispatch()

    fun getLastOpenDate(): LocalDate {
        val today = Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault()).date
        return when (today.dayOfWeek) {
            DayOfWeek.SUNDAY -> {
                today.minus(DatePeriod(0, 0, 2))
            }
            DayOfWeek.MONDAY -> {
                today.minus(DatePeriod(0, 0, 3))
            }
            else -> {
                today.minus(DatePeriod(0, 0, 1))
            }
        }
    }

    fun exchangeRateUpdater(timeInterval: Long): Job {
        return CoroutineScope(Dispatchers.Default).launch {
            while (isActive) {
                exchangeRateCountDispatcher(UpdateExpectedExchangeRateCount((5)))
                val usdGBPQuote = getForexQuote(ForexRequest("USD", "GBP"))
                exchangeRateDispatcher(
                    UpdateExchangeRate(
                        usdGBPQuote.sourceCurrency,
                        usdGBPQuote.targetCurrency,
                        usdGBPQuote.exchangeRate,
                        Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault())
                    )
                )
                val usdEURQuote = getForexQuote(ForexRequest("USD", "EUR"))
                exchangeRateDispatcher(
                    UpdateExchangeRate(
                        usdEURQuote.sourceCurrency,
                        usdEURQuote.targetCurrency,
                        usdEURQuote.exchangeRate,
                        Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault())
                    )
                )
                val usdCADQuote = getForexQuote(ForexRequest("USD", "CAD"))
                exchangeRateDispatcher(
                    UpdateExchangeRate(
                        usdCADQuote.sourceCurrency,
                        usdCADQuote.targetCurrency,
                        usdCADQuote.exchangeRate,
                        Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault())
                    )
                )
                val usdILSQuote = getForexQuote(ForexRequest("USD", "ILS"))
                exchangeRateDispatcher(
                    UpdateExchangeRate(
                        usdILSQuote.sourceCurrency,
                        usdILSQuote.targetCurrency,
                        usdILSQuote.exchangeRate,
                        Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault())
                    )
                )
                exchangeRateDispatcher(
                    UpdateExchangeRate(
                        "USD",
                        "USD",
                        1.0,
                        Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault())
                    )
                )
                delay(timeInterval)
            }
        }
    }

    useEffectOnce {
        scope.launch(Dispatchers.Main) {
            val quoteDate = getLastOpenDate()
            val quoteRequest = QuoteRequest("AMZN", quoteDate.toString())
            val thirtyDayAverageQuote = getThirtyDayAvgStockQuote(quoteRequest)
            dispatch(SharePriceUpdate(sharePrice = ThirtyDayAverageQuote.fromTO(thirtyDayAverageQuote)))
            val repeatJob = exchangeRateUpdater(60000L)
            loadCompList(LoadCompList("compList"))
        }
    }

    div {
        className = ClassName("px-5")
        div {
            className = ClassName("row")
            div {
                className = ClassName("col")
                h1 {
                    +"Total Comp Performance"
                }
            }
        }

        SharePrice {}

        SpacerRow {}

        CompForm {}

        SpacerRow {}

        TotalCompCalculation {}

        SpacerRow {}

        CompTable {}
    }
}
