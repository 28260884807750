package components

import globalStore
import react.FC
import react.Props
import react.redux.Provider

val AppWrapper = FC<Props> { props ->
    Provider {
        this.store = globalStore
        App()
    }


}