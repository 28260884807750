package entities

import kotlinx.serialization.Serializable

@Serializable
data class ForexRequest(
    val sourceCurrency: String,
    val targetCurrency: String
) {
    companion object {
        const val path = "/forex/quote"
    }
}