package components

import actions.AddTcT
import csstype.ClassName
import react.Dispatch
import react.FC
import react.Props
import react.dom.html.InputType
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.label
import react.redux.useDispatch
import react.redux.useSelector
import state.ApplicationStore

val TotalCompTarget = FC<Props> { _ ->
    val target = useSelector { state: ApplicationStore ->
        state.compCalculation.totalCompTarget
    }
    val dispatch: Dispatch<AddTcT> = useDispatch()

    div {
        className = ClassName("form-group")
        label {
            htmlFor = "totalCompTarget"
            +"Total Comp Target"
        }
        input {
            className = ClassName("form-control")
            id = "totalCompTarget"
            name = id
            type = InputType.text
            defaultValue = target.toString()
            onChange = { event ->
                val newAmount = event.target.value
                if (newAmount.isNotEmpty()) {
                    dispatch(AddTcT(newAmount.toDouble()))
                }
            }
        }
    }
}