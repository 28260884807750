package api

import entities.*
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.request.*
import io.ktor.http.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.serialization.json.Json


const val endpoint =
    "https://nvdc411i62.execute-api.us-east-1.amazonaws.com/Prod"

val client = HttpClient {
    install(ContentNegotiation) {
        json(Json {
            prettyPrint = true
            isLenient = true
        })
    }
}

suspend fun getStockQuote(quoteRequest: QuoteRequest): StockQuoteTO {
    return client.post(endpoint + StockQuote.path) {
        contentType(ContentType.Application.Json)
        setBody(quoteRequest)
    }.body()
}

suspend fun getThirtyDayAvgStockQuote(quoteRequest: QuoteRequest): ThirtyDayAverageQuoteTO {
    return client.post(endpoint + ThirtyDayAverageQuote.path) {
        contentType(ContentType.Application.Json)
        setBody(quoteRequest)
    }.body()
}


suspend fun getForexQuote(quoteRequest: ForexRequest): ForexResponse {
    return client.post(endpoint + ForexRequest.path) {
        contentType(ContentType.Application.Json)
        setBody(quoteRequest)
    }.body()
}