package entities

import kotlinx.datetime.Clock
import kotlinx.datetime.LocalDate
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime

@kotlinx.serialization.Serializable
class CompCalculation(
    var alias: String = "",
    var currency: String = "USD",
    var quoteDate: LocalDate = Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault()).date,
    var baseComp: Double = 0.0,
    var otherCash: Double = 0.0,
    var shares: Int = 0,
    var sharePrice: Double = 0.0,
    var totalCompTarget: Double = 0.0,
    var totalComp: Double = 0.0,
    var vestingValue: Double = 0.0
) {

    fun copy(): CompCalculation {
        return CompCalculation(
            alias,
            currency,
            quoteDate,
            baseComp,
            otherCash,
            shares,
            sharePrice,
            totalCompTarget,
            totalComp,
            vestingValue
        )
    }
}