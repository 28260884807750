package components

import csstype.ClassName
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.useState

external interface SpacerRowProps : Props {
    var isForm: Boolean
    var divId: String?
}

val SpacerRow = FC<SpacerRowProps> { props ->
    val charPool: List<Char> = ('a'..'z') + ('A'..'Z') + ('0'..'9')
    val generatedDivId: String = List(20) { charPool.random() }.joinToString("")
    val isForm by useState(false)
    val divId: String? by useState(props.divId)

    val cssClass = if (isForm) {
        "form-row, py-2"
    } else {
        "row, py-2"
    }



    div {
        this.id = with(divId) {
            this ?: generatedDivId
        }
        this.className = ClassName(cssClass)
    }

}